const MESSAGE = {
  EN: {
    collapse_btn_txt: 'Collapse List',
    expand_btn_txt: 'Expand List'
  },
  TH: {
    collapse_btn_txt: 'ซ่อนลิสต์',
    expand_btn_txt: 'ขยายลิสต์'
  }
}

export default MESSAGE
