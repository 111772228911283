import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import UserRowCard from '../UserRowCard/Loadable'
import UserCollapseWrapper from '../UserCollapseWrapper/Loadable'

import ProjectUserCardStyled from './styledComponent'
import adminImage from '../../asset/images/project-user-card/profile.svg'

import MESSAGE from './message'

const COLLABORATOR_AMOUNT = 5

class ProjectUserCard extends PureComponent {
  getMessage(property) {
    const msg = MESSAGE[this.props.language]
    return msg[property]
  }

  getScreenshotSection() {
    return <div className="screenshot-container">{this.props.screenshot ? <img src={this.props.screenshot} alt="" /> : null}</div>
  }

  getAdminIcon() {
    return (
      <div className="screenshot-container admin-icon">
        <img src={adminImage} alt="" />
      </div>
    )
  }

  getDetailSection() {
    return (
      <div className="detail-section-container">
        {this.props.isAdminMode ? this.getAdminIcon() : this.getScreenshotSection()}
        <div className="detail-container prevent-text-overflow text-style">{this.props.projectName}</div>
      </div>
    )
  }

  getCollaboratorSection() {
    const viewerTxt = `Viewer ${this.props.users.length}/${COLLABORATOR_AMOUNT}`
    return <div className="collaborator-section-container text-style">{this.props.isAdminMode ? this.props.users.length : viewerTxt}</div>
  }

  getCardHeader() {
    return (
      <div className="project-user-card-header-container">
        {this.getDetailSection()}
        {this.getCollaboratorSection()}
      </div>
    )
  }

  getListAdmin() {
    const listAdmin = this.props.users.map((userData) => {
      return (
        <UserRowCard
          key={userData.id}
          userID={userData.id}
          email={userData.email}
          active={userData.active}
          registerURL={userData.register_url}
          listProject={this.props.listProject}
          projectID={this.props.projectID}
          onUpdateUser={this.props.onUpdateUser}
          onRemoveUser={this.props.onRemoveUser}
          language={this.props.language}
          showCopyRegisterLinkBtn
        />
      )
    })
    return listAdmin
  }

  getListUsers() {
    const managers = this.props.managers.map((userData) => {
      if (typeof userData !== 'undefined') {
        return (
          <UserRowCard
            key={userData.id}
            userID={userData.id}
            email={userData.email}
            active={userData.active}
            listManager={this.props.listManager}
            isManager={userData.manager}
            registerURL={userData.register_url}
            listProject={this.props.listProject}
            projectID={this.props.projectID}
            onUpdateUser={this.props.onUpdateUser}
            onRemoveUser={this.props.onRemoveUser}
            language={this.props.language}
            showCopyRegisterLinkBtn={this.props.currentUser.admin}
            showDetailBtn={this.props.currentUser.admin}
            showDeleteBtn={this.props.currentUser.admin}
          />
        )
      }
      return null
    })
    const users = this.props.users.map((userData) => {
      return (
        <UserRowCard
          key={userData.id}
          userID={userData.id}
          email={userData.email}
          active={userData.active}
          isManager={userData.manager}
          registerURL={userData.register_url}
          listProject={this.props.listProject}
          projectID={this.props.projectID}
          onUpdateUser={this.props.onUpdateUser}
          onRemoveUser={this.props.onRemoveUser}
          language={this.props.language}
          showCopyRegisterLinkBtn
          showDetailBtn
          showDeleteBtn
        />
      )
    })
    return (
      <>
        <UserCollapseWrapper title="Manager">{managers}</UserCollapseWrapper>
        <UserCollapseWrapper title="Viewer">{users}</UserCollapseWrapper>
      </>
    )
  }

  getContent() {
    return <div className="project-list-user-container">{this.props.isAdminMode ? this.getListAdmin() : this.getListUsers()}</div>
  }

  render() {
    return (
      <ProjectUserCardStyled>
        {this.getCardHeader()}
        {this.getContent()}
      </ProjectUserCardStyled>
    )
  }
}

ProjectUserCard.defaultProps = {
  language: 'EN',
  isAdminMode: false,
  projectName: '',
  screenshot: '',
  projectID: '',
  currentUser: {
    admin: false,
    manager: false
  }
}

ProjectUserCard.propTypes = {
  language: PropTypes.string,
  isAdminMode: PropTypes.bool,
  currentUser: PropTypes.shape({
    admin: PropTypes.bool,
    manager: PropTypes.bool
  }).isRequired,
  projectID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  projectName: PropTypes.string,
  screenshot: PropTypes.string,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      email: PropTypes.string.isRequired,
      active: PropTypes.bool.isRequired
    })
  ).isRequired,
  managers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      email: PropTypes.string.isRequired,
      active: PropTypes.bool.isRequired,
      projects: PropTypes.array.isRequired
    })
  ),
  listManager: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      email: PropTypes.string.isRequired,
      active: PropTypes.bool.isRequired,
      projects: PropTypes.array
    })
  ).isRequired,
  listProject: PropTypes.arrayOf(
    PropTypes.shape({
      project_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })
  ),
  onUpdateUser: PropTypes.func.isRequired,
  onRemoveUser: PropTypes.func.isRequired
}

export default ProjectUserCard
