import styled from 'styled-components'

export default styled.div`
  width: 100%;
  background: white;
  margin-bottom: 20px;
  border-radius: 5px;
  .project-user-card-header-container {
    display: flex;
    width: 100%;
    height: 60px;
    background: ${(props) => props.theme.jadeGreen};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    .detail-section-container {
      position: relative;
      display: flex;
      align-items: center;
      width: 385px;
      .screenshot-container {
        width: 60px;
        height: 60px;
        border-top-left-radius: 5px;
        background: ${(props) => props.theme.imgBackground};
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-top-left-radius: 5px;
        }
        &.admin-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          background: white;
          img {
            width: 30px;
            height: 30px;
            background: ${(props) => props.theme.imgBackground};
            padding: 5px;
            border-radius: 50%;
          }
        }
      }
      .detail-container {
        width: 325px;
        padding-left: 20px;
        padding-right: 20px;
        height: 36px;
        font-family: 'Prompt', sans-serif;
        font-weight: bold;
        font-size: 24px;
        color: white;
      }
      &:after {
        content: '';
        width: 1px;
        height: calc(100% - 16px);
        background: ${(props) => props.theme.imgBackground};
        position: absolute;
        right: 0px;
        top: 8px;
      }
    }
    .collaborator-section-container {
      position: relative;
      width: 210px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Prompt', sans-serif;
      font-weight: bold;
      font-size: 24px;
      text-align: center;
      color: white;
      &:after {
        content: '';
        width: 1px;
        height: calc(100% - 16px);
        background: ${(props) => props.theme.imgBackground};
        position: absolute;
        right: 0px;
        top: 8px;
      }
    }
    .option-section-container {
      width: 595px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0px 30px;
      .header-option-btn {
        font-size: 24px;
        margin-left: 20px;
      }
    }
  }
`
